import React from "react";
import Layout from "../components/layout";
import WhiteCard from "../components/white-card";
import { Link } from "gatsby";
import {
  GiBabyBottle,
  GiBookCover,
  GiBookmarklet,
  GiBoots,
  GiBubbles,
  GiBurningEmbers,
  GiClick,
  GiClothesline,
  GiCook,
  GiFireExtinguisher,
  GiFireplace,
  GiKeyLock,
  GiLinkedRings,
  GiShower,
  GiSmokingPipe,
  GiSofa,
  GiStairs,
  GiSuitcase,
  GiTap,
  GiTrashCan,
  GiTreeBranch,
  GiWifiRouter,
  GiWindpump,
} from "react-icons/gi";
import autoFan from "../images/hvac/autofan.webp";
const h1ClassNames = "text-center font-bold text-4xl pb-5";
const h2ClassNames = "italic font-bold text-3xl pt-4";
const h3ClassNames = "pt-2 font-bold text-2xl";
const pClassNames = "pt-4 pl-2 text-xl";
const ulClassNames = "pl-2";
const olClassNames = "list-decimal pl-4";
const liClassNames = "pt-2 text-xl";

export function Head() {
  return (
    <>
      <title>Overlook Chalet</title>
      <description>Overlook Chalet</description>
    </>
  );
}

const GuestsPage = () => {
  return (
    <Layout>
      <WhiteCard>
        <section>
          <GiBookmarklet className="m-auto text-4xl" />

          <h1 className={h1ClassNames}>House Manual</h1>
          <div className="flex self-center items-center">
            <a
              className="inline-block rounded-sm bg-oc-green-dark font-semibold text-oc-beige pt-1 pb-1 pr-2 pl-2 text-center m-auto border-2 border-oc-green"
              href="./mysa-instructions"
            >
              Climate Control Instructions Here!
            </a>
          </div>
          <p className={pClassNames}>
            Welcome to the Overlook Chalet! We&#39;re so glad you&#39;re here
            and hope your trip was easy.
          </p>

          <p className={pClassNames}>
            Our Property managers, Craig & Chelsea, will be your contact during
            your stay. You can contact them through the Airbnb app for routine
            questions/comments or on your mobile - Craig&nbsp;
            <a className="text-blue-800 font-bold" href="tel:5186103492">
              (518-610-3492)&nbsp;
            </a>
            or Chelsea&nbsp;
            <a className="text-blue-800 font-bold" href="tel:5182606820">
              (518-260-6820)
            </a>
            , with urgent requests or emergencies.
          </p>
          <p className={pClassNames}>
            We are constantly working to improve our home.  Lawn mowing, and
            other drive-by (outdoor) activities might overlap your stay, but
            will not be intrusive. We&#39;ll do our best to give you a heads up
            before anyone stops by.
          </p>
          <p
            className={
              pClassNames + " border-red-500 border-2 text-center p-2 pb-4"
            }
          >
            <b>
              <em>
                Note: Please do not use the firewood under the deck as it is not
                seasoned and will produce large amounts of smoke.
              </em>
            </b>
          </p>

          <h2 id="houseaccess" className={h2ClassNames}>
            House Access:
            <GiKeyLock className="m-auto text-3xl" />
          </h2>

          <p className={pClassNames}>
            The door code for the side entrance will be sent 24-48hrs prior to
            check-in. Please be sure to lock all doors whenever you leave the
            house.
          </p>
          <h2 id="general" className={h2ClassNames}>
            General:
          </h2>
          <p className={pClassNames}>
            <GiBoots className="m-auto text-3xl" />
            Shoes - we ask that you please remove your shoes upon entering the
            house. This will keep the house clean and dry and make your stay
            more enjoyable.
          </p>
          <p className={pClassNames}>
            <GiSmokingPipe className="m-auto text-3xl" />
            This is a non-smoking property. Smoking and vaping are not permitted
            in or around the house, deck, hot tub, firepit or anywhere on the
            property. We appreciate your compliance with this rule.
          </p>
          <p className={pClassNames}>
            <GiWindpump className="m-auto text-3xl" />
            Fans - We have fans in all bedrooms and the living room.  The light
            switches for the associated lights must be turned on for the remotes
            to operate the fans.
          </p>
          <p className={pClassNames}>
            Climate Control: Each room has its own Mitsubish<sup>®</sup>{" "}
            Mini-split climate control unit. Control these using the Mysa
            <sup>®</sup> smart thermostats. You can find{" "}
            <a
              className="text-blue-600"
              href="/mysa-instructions"
              target="_blank"
            >
              detailed operating instructions here.
            </a>
          </p>
          <p className={pClassNames}>
            <GiBabyBottle className="m-auto text-3xl" />A pack & play is
            available in the hall closet upstairs. Kids plates and utensils are
            available in the kitchen.
          </p>
          <h2 id="internet" className={h2ClassNames}>
            Internet/Streaming:
            <GiWifiRouter className="m-auto text-3xl" />
          </h2>
          <h3 className={h3ClassNames}>Wifi Access:</h3>
          <p className={pClassNames}>
            Please join the network <em>OverlookChalet</em>. The password can be
            found on the mantle.
          </p>
          <p className={pClassNames}>
            Our Internet provider is Starlink.  It is extremely reliable,
            stable, and fast (~200 Mbps down/15 up), but if you have any trouble
            please let us know. You can troubleshoot by unplugging and plugging
            back in the white router, located in the living room to the right of
            the fireplace. Please only do this once though as powercycling 3
            times will reset the internet entirely.
          </p>

          <h2 id="livingroom" className={h2ClassNames}>
            Living Room:
            <GiSofa className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            The living room is equipped with a 4K Toshiba<sup>®</sup> TV and
            Sonos<sup>®</sup>
            Surround Sound. A Sonos<sup>®</sup> Move is also available in the
            Kitchen for your use. Feel free to take this outside but please keep
            it dry and return it to the dock when you are finished enjoying it.
          </p>
          <p className={pClassNames}>
            The TV can access most streaming services like Netflix, Hulu,
            Disney, Amazon Prime, HBO Max, etc with your login and password.
          </p>
          <h2 id="readingnook" className={h2ClassNames}>
            Reading Nook:
            <GiBookCover className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            Games are available in the straw basket in the reading nook
            (upstairs landing). Please enjoy and return them to the basket when
            you are finished using them.
          </p>
          <h2 id="fireplace" className={h2ClassNames}>
            Fireplace:
            <GiFireplace className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            The living room is equipped with a wood burning fireplace. A starter
            log is provided and you can purchase additional starter logs and
            firewood at nearby convenience stores and grocery stores.
            <p
              className={
                pClassNames + " border-red-500 border-2 text-center p-2 pb-4"
              }
            >
              <b>
                <em>
                  Please do not use the firewood under the deck as it is not
                  seasoned and will produce large amounts of smoke.
                </em>
              </b>
            </p>
          </p>
          <h2 id="outdoors" className={h2ClassNames}>
            Outdoors:
            <GiTreeBranch className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            The Edison bulbs can be controlled using the black remote next to
            the doors to the deck. They automatically turn on when it is dark
            outside, but feel free to adjust to your preference.
          </p>
          <p className={pClassNames}>
            Please do not take glass dishes or cups outside. We have plastic
            glasses for this purpose in the kitchen cabinets.
          </p>
          <p className={pClassNames}>
            The property is sprayed monthly for mosquitos and ticks, but we are
            in the country, so please take the necessary precautions.
          </p>
          <p className={pClassNames}>
            While our house is fairly secluded, we do have neighbors - please
            take noise levels into consideration.
          </p>
          <h2 id="firepit" className={h2ClassNames}>
            Fire Pit:
            <GiBurningEmbers className="m-auto text-3xl" />
          </h2>
          <ul className={ulClassNames}>
            <li className={liClassNames}>
              Fire cannot be left unattended at any time 
            </li>
            <li className={liClassNames}>
              Fire must be extinguished fully before leaving the fire pit
            </li>
            <li className={liClassNames}>
              Starter logs and firewood can be purchased at grocery stores, gas
              stations, and home improvement stores nearby.
            </li>
            <li className={liClassNames}>
              <p
                className={
                  pClassNames + " border-red-500 border-2 text-center p-2 pb-4"
                }
              >
                <b>
                  <em>
                    Please do not use the firewood under the deck as it is not
                    seasoned and will produce large amounts of smoke.
                  </em>
                </b>
              </p>
            </li>
          </ul>
          <h2 id="hottub" className={h2ClassNames}>
            Hot Tub:
            <GiBubbles className="m-auto text-3xl" />
          </h2>
          <h3 className={h3ClassNames}>Our cleaning practices:</h3>

          <p className={pClassNames}>
            We take extra care to clean and balance the swim spa weekly, before
            you arrive, so you can rest assured that it is hygienic. We use a
            water conditioner, designed to prevent drying of the skin. This
            conditioner can sometimes rest on top of the water, but don't worry,
            it is clean & harmless. Should you find this uncomfortable, you can
            find turtle sponges in the basement that can be used to absorb
            excess conditioner. Please feel free to toss 2 of them in the water
            and remove any that are already in the tub. This should aid in the
            absorption of the excess conditioner.
          </p>
          <h3 className={h3ClassNames}>How to operate:</h3>
          <p className={pClassNames}>
            The up/down arrows control the temperature. 
          </p>
          <p className={pClassNames}>
            Press the light button to turn on and customize the light colors.
            Press repeatedly to change and turn off the lights. 
          </p>
          <p className={pClassNames}>
            The jets button will turn on seating jets.
          </p>
          <p className={pClassNames}>
            Press ‘workout&#39; to turn on the swim current. You can adjust the
            speed of the current using the up and down arrows.
          </p>
          <p className={pClassNames}>
            There are lots of options so have fun and find your favorite seat
            and jet configuration!
          </p>
          <p className={pClassNames}>
            <b>
              Please be sure to cover when finished. This will keep the hot tub
              clean and maintain the temperature.
            </b>
          </p>
          <h2 id="basement" className={h2ClassNames}>
            Basement:
            <GiStairs className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            The basement is not kid-safe. Please enter at your own risk and do
            not leave children unattended.
          </p>
          <h2 id="bathrooms" className={h2ClassNames}>
            Bathrooms:
            <GiShower className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            The bathrooms are stocked with soap, shampoo, body wash, toilet
            paper (x3), and hair dryers
          </p>
          <p className={pClassNames}>
            Please avoid removing makeup with bath towels - we have black
            washcloths specifically for this purpose
          </p>
          <p className={pClassNames}>
            The house runs on a septic tank, so please be mindful of how much
            toilet paper you use and flush more than once if necessary. Please
            do not flush sanitary products or baby wipes. Sanitary bags can be
            found in the drawers of each bathroom.
          </p>
          <h2 id="kitchen" className={h2ClassNames}>
            Kitchen:
            <GiCook className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            Please help yourself to anything in the kitchen including tea,
            coffee, herbs and spices, olive oil, cookware, utensils, and
            appliances
          </p>
          <p className={pClassNames}>
            Please don&#39;t forget to turn everything off and to wash all
            dishes and utensils prior to check-out
          </p>
          <h2 id="laundry" className={h2ClassNames}>
            Laundry:
            <GiClothesline className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            Feel free to use our washer/dryer in the entry hall.
          </p>
          <p className={pClassNames}>Detergent is provided</p>
          <p className={pClassNames}>
            Please don&#39;t forget to empty the lint trap on the dryer after
            every use
          </p>
          <p className={pClassNames}>
            Ironing board and iron are in the closet of the “blue room” at the
            rear of the house
          </p>
          <h2 id="trash" className={h2ClassNames}>
            Trash: 
            <GiTrashCan className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            Recycling is single stream and doesn&#39;t have to be separated;
            paper, plastic, glass, can be placed in the same recycling bins
          </p>
          <p className={pClassNames}>
            Please be sure to tie bags securely before placing them in the bins
            outside.
          </p>
          <p className={pClassNames}>
            The outside bins are animal proof. Please be sure the lids close
            securely - they should latch closed.
          </p>
          <p className={pClassNames}>
            Trash bins will be emptied upon your departure.
          </p>
          <h2 id="safety" className={h2ClassNames}>
            Safety info: 
            <GiFireExtinguisher className="m-auto text-3xl" />
          </h2>
          <p className={pClassNames}>
            We have fire extinguishers on each floor - one under the kitchen
            sink and one in the upstairs linen closet.
          </p>
          <p className={pClassNames}>
            A first aid kit can be found in the kitchen drawer labeled
            “Emergency” along with a fire blanket.
          </p>
          <p className={pClassNames}>
            For urgent requests, please call Craig, our property manager. For
            emergencies, always call 911!
          </p>
          <p className={pClassNames}>
            We have one security camera mounted on the deck. This camera
            <b> only</b> has a view of the driveway, which we monitor for
            deliveries, maintenance and capacity. Rest assured you have privacy
            in the hot tub, and throughout the house.
          </p>
          <Link
            href="/checkout"
            className="m-auto flex w-2/3 mt-7 inline-block rounded-sm bg-oc-green-dark font-semibold text-oc-beige pt-1 pb-1 pr-2 pl-2 text-center border-2 border-oc-green"
          >
            <h2 id="checkout" className={`{h2ClassNames} m-auto flex text-3xl`}>
              <GiClick />
              Check out instructions
              <GiClick />
            </h2>
          </Link>

          <h2 className={h2ClassNames + " text-center text-blue-800"}>
            <a href="/rental-agreement-final.pdf" target="_blank">
              Rental Agreement
            </a>
          </h2>
          <a href="/privacy-security">Privacy policy</a>
        </section>
      </WhiteCard>
    </Layout>
  );
};
export default GuestsPage;
